<template>
  <div class="preEntryOfferCheck">
    <!-- 我的个人资料 -->
    <van-nav-bar :title="title"
                 :fixed="true" />
    <div class="OfferCheck_content"
         v-if="showContent">
      <div v-if="loading"
           class="loading">
        <van-loading type="spinner"
                     color="#1989fa" />
      </div>
      <van-collapse v-else
                    ref="offerCheckCollapse"
                    v-model="activeNames">
        <van-collapse-item v-for="(item, index) in newFiledList.filter(f => { return f.name })"
                           :key="index"
                           :title="item.name"
                           :name="index">
          <div class="peField"
               v-for="(it, idx) in item.origin.filter(or => { return or.formshow })"
               :key="idx"
               :style="colcount > 1 ? { width: it.width } : ''">
            <!-- 不可编辑的输入框（除开关类型和文本域） -->
            <van-field v-if="
                !it.app_canedit &&
                  it.controltype != 'checkbox' &&
                  it.controltype != 'memo'
              "
                       class="not_canedi"
                       :label="it.chnname"
                       v-model="it.fieldValue"
                       readonly
                       :colon="true" />
            <!-- 可编辑的普通输入框 -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'edit'"
                       :required="it.mustinput"
                       :label="it.chnname"
                       right-icon="edit"
                       v-model="it.fieldValue"
                       :colon="true"
                       :placeholder="$t('module.PleaseEnter')"
                       @change="
                val => {
                  b4valuechage(val, it)
                }
              " />
            <!-- 下拉选择器 -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'combobox'"
                       readonly
                       clickable
                       :colon="true"
                       right-icon="edit"
                       name="picker"
                       :required="it.mustinput"
                       v-model="it.fieldValue"
                       :label="it.chnname"
                       :placeholder="$t('module.PleaseChoose')"
                       @click="it.ispoptree ? showTree(it) : getPickerData(it, index, idx)"
                       @change="
                val => {
                  b4valuechage(val, it)
                }
              ">
              <template v-if="it.ispoptree"
                        #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down"
                          @click="showTree(it)" />
              </template>
            </van-field>
            <!-- 不可编辑的开关 -->
            <van-field v-else-if="!it.app_canedit && it.controltype == 'checkbox'"
                       name="switch"
                       :colon="true"
                       class="not_canedi"
                       :label="it.chnname">
              <template #input>
                <van-switch v-model="newFiledList[index].origin[idx].fieldValue"
                            disabled
                            size="20" />
              </template>
            </van-field>
            <!-- 可编辑的开关 -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'checkbox'"
                       right-icon="edit"
                       name="switch"
                       :colon="true"
                       :required="it.mustinput"
                       :label="it.chnname"
                       @change="
                val => {
                  b4valuechage(val, it)
                }
              ">
              <template #input>
                <van-switch v-model="newFiledList[index].origin[idx].fieldValue"
                            size="20" />
              </template>
            </van-field>
            <!-- 不可编辑的文本域 -->
            <van-field v-else-if="!it.app_canedit && it.controltype == 'memo'"
                       :label="it.chnname"
                       class="not_canedi"
                       :colon="true"
                       readonly
                       v-model="it.fieldValue"
                       rows="3"
                       autosize
                       type="textarea">
              <template #input>
                <div v-html="it.fieldValue.replace(/\n/g,'<br>')"></div>
              </template>
            </van-field>
            <!-- 可编辑的文本域 -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'memo'"
                       :label="it.chnname"
                       v-model="it.fieldValue"
                       :required="it.mustinput"
                       right-icon="edit"
                       :colon="true"
                       rows="3"
                       autosize
                       type="textarea"
                       :placeholder="$t('module.PleaseEnter')">
              <!-- <template #input>
                <div v-if="it.app_canedit"
                     v-html="it.fieldValue.replace(/\n/g,'<br>')"></div>
                <input v-else
                       v-html="it.fieldValue.replace(/\n/g,'<br>')">
              </template> -->
            </van-field>
            <!-- 日期选择器 -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'datetime'"
                       clickable
                       :readonly="it.mustinput"
                       :clearable="!it.mustinput"
                       :colon="true"
                       clear-trigger="always"
                       :required="it.mustinput"
                       right-icon="edit"
                       name="datePicker"
                       :label="it.chnname"
                       :value="formatDate(it.fieldValue, 'yyyy-MM-dd')"
                       :placeholder="$t('module.SelectDate')"
                       @clear="clearDate(index, idx)"
                       @click="getDatePicker(index, idx)" />
            <!-- 日期时间选择器 -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'longdatetime'"
                       clickable
                       :readonly="it.mustinput"
                       :colon="true"
                       :clearable="!it.mustinput"
                       clear-trigger="always"
                       :required="it.mustinput"
                       right-icon="edit"
                       name="datetimePicker"
                       :label="it.chnname"
                       :value="formatDate(it.fieldValue)"
                       :placeholder="$t('module.SelectDateTime')"
                       @clear="clearDate(index, idx)"
                       @click="getDateTimePicker(index, idx)" />
            <!-- maskedit -->
            <van-field v-else-if="it.app_canedit && it.controltype == 'maskedit'"
                       :label="it.chnname"
                       v-model="it.fieldValue"
                       :required="it.mustinput"
                       :colon="true"
                       :placeholder="$t('module.PleaseEnter')"
                       @input="keyup(it)"
                       @change="
                val => {
                  b4valuechage(val, it)
                }
              ">
              <template v-if="it.maskeditstr == 'CitySelector'"
                        #right-icon>
                <van-icon name="location-o"
                          @click="citySelectorFocus(it)" />
              </template>
              <!-- 身份证号码 图标 -->
              <!-- <template v-else-if="list.fieldname == 'idcardnum' && use_identify_ocr"
                        #right-icon>
                <van-icon name="photo-o"
                          @click="showIdCardDia(list)" />
              </template> -->
            </van-field>
          </div>
        </van-collapse-item>
        <!-- 附件上传 -->
        <van-collapse-item v-if="use_at_file"
                           :title="'附件信息'"
                           :name="newFiledList.length">
          <div v-if="atfilenamelist.length>0"
               class="picbox">
            <div class="pp"
                 v-for="(item, index) in fileList"
                 :key="index">
              <van-image width="20vw"
                         height="20vw"
                         alt="1"
                         :src="item.url"
                         @click="openUpload(item)" />
              <div v-if="item.url"
                   @click="del(item)"
                   class="van-uploader__preview-delete">
                <van-icon name="cross" />
              </div>
              <div>{{item.name}}</div>
            </div>
            <van-uploader v-show="false"
                          ref="uploader"
                          v-model="fileList2"
                          :lazy-load="true"
                          :max-count="1"
                          accept="image/*, .pdf, .doc, .docx, .jpg, .png, .xls, .xlsx"
                          :max-size="10 * 1024 * 1024"
                          :before-read="beforeRead"
                          :after-read="afterReadAttr"
                          @oversize="onOversize"></van-uploader>
          </div>
          <div class="iptline"
               v-else>
            <label>
              附件
              <p>最多15张</p>
            </label>
            <van-uploader v-model="fileList"
                          multiple
                          :lazy-load="true"
                          :max-count="15"
                          accept="image/*, .pdf, .doc, .docx, .jpg, .png, .xls, .xlsx"
                          :max-size="10 * 1024 * 1024"
                          :preview-size="'20vw'"
                          :after-read="afterReadAttr"
                          @delete="deletefile"
                          @oversize="onOversize"></van-uploader>
          </div>
        </van-collapse-item>
        <!-- 签名放在页面底部 -->
        <van-collapse-item v-if="show_sign_form"
                           :title="'签名栏'"
                           :name="newFiledList.length+1">
          <div style="width: 100%; height: 100%; padding: 0.1rem"
               class="sign_form">
            <div class="mydraw">
              <div class="canvasContainer">
                <div class="cont canvasDiv">
                  <!-- :class="windowsignremark?'windowsignremark':''" -->
                  <canvas style="background: #fff"
                          id="canvas"
                          ref="mycanvas"
                          @touchstart="onTouchStart"
                          @touchmove="onTouchMove"
                          @touchend="onTouchEnd">Canvas画板</canvas>
                </div>
                <div class="signFooter">
                  <!-- <div class="button_can"
                       @click="clear">
                    {{ $t("signaturePad.spTxt3") }}
                  </div> -->
                  <van-button type="warning"
                              class="button_can"
                              @click="clear">{{ $t("signaturePad.spTxt3") }}</van-button>
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <!-- 普通下拉选的弹窗 -->
      <van-popup v-model="showPicker"
                 position="bottom"
                 round>
        <van-picker show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"></van-picker>
      </van-popup>
      <!-- 日期选择弹窗 -->
      <van-popup v-model="showDatePicker"
                 position="bottom"
                 round>
        <van-datetime-picker type="date"
                             v-model="pickDate"
                             :min-date="minDate"
                             :max-date="maxDate"
                             :formatter="formatter"
                             @confirm="onDateConfirm"
                             @cancel="showDatePicker = false" />
      </van-popup>
      <!-- 日期时间选择弹窗 -->
      <van-popup v-model="showDateTimePicker"
                 position="bottom"
                 round>
        <van-datetime-picker type="datetime"
                             v-model="pickDateTime"
                             :min-date="minDate"
                             :max-date="maxDate"
                             :formatter="formatter"
                             @confirm="onDateTimeConfirm"
                             @cancel="showDateTimePicker = false" />
      </van-popup>
      <!-- 省市区选择器弹窗 -->
      <van-popup v-model="showCitySelector"
                 round
                 position="bottom">
        <van-area title="选择省市区"
                  :area-list="areaList"
                  :columns-placeholder="['请选择', '请选择', '请选择']"
                  @confirm="confirmCity"
                  @cancel="showCitySelector = false" />
      </van-popup>
    </div>
    <div class="footer"
         v-if="!loading && !ispost && showContent">
      <van-button type="info"
                  size="small"
                  :loading="saveloading"
                  loading-text="保存中..."
                  @click="savePersonInfo"
                  :disabled="isDisable">{{ $t('module.Save') }}</van-button>
    </div>

    <!-- 树弹窗 -->
    <van-popup class="tree_popup"
               v-model="showTreePop"
               closeable
               close-icon="arrow-left"
               close-icon-position="top-left"
               position="right"
               :style="{ width: '100%', height: '100%' }">
      <van-sticky class="fixed_div">
        <div class="tree_title">
          <span>{{ `请选择${Selectformdata.chnname}` }}</span>
        </div>
        <div class="tree_search">
          <van-search v-model="searchTreeVal"
                      placeholder="请输入搜索关键词"
                      @search="onSearchTree" />
        </div>
        <div class="tree_head">
          <span class="head_start"
                @click="backTo(true)">开始</span>
          <span v-for="(ele, idex) in clickParentTree"
                :key="idex"
                :class="idex == clickParentTree.length - 1 ? 'theLast' : 'noLast'"
                @click="backTo(ele)">
            <span class="division">></span>
            {{ ele.keyname }}
          </span>
        </div>
        <van-divider :style="{
            color: '#E8E8E8',
            borderColor: '#E8E8E8',
            margin: '5px 0 0 0'
          }" />
      </van-sticky>
      <div class="tree_body">
        <!-- 岗位树和员工树 -->
        <div v-if="whichTree == 'station' || whichTree == 'employee'">
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <!-- 父节点 -->
            <van-cell class="parents_cell"
                      v-for="(item, index) in parentTreeList"
                      :key="index + item.keyname"
                      :title="item.keyname">
              <template #default>
                <div :class="item.hasLower ? 'haveClo' : 'noClo'"
                     @click="checkParent(item)">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
              <template #icon>
                <van-checkbox v-model="item.checked"
                              @click="tickParent(item)"
                              shape="square"></van-checkbox>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell class="childs_cell"
                      v-for="(it, idx) in childsTreeList"
                      :key="idx">
              <template #title>
                <van-checkbox v-model="it.checked"
                              shape="square">
                  <van-button size="small"
                              :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                              :type="whichTree == 'employee' ? 'warning' : 'primary'"></van-button>
                  <span class="check_name">{{ it.keyname }}</span>
                </van-checkbox>
              </template>
            </van-cell>
          </div>
          <!-- 非多选 -->
          <div v-else>
            <!-- 父节点 -->
            <van-cell class="parents_cell"
                      v-for="(item, index) in parentTreeList"
                      :key="index + item.keyname"
                      :title="item.keyname"
                      @click="checkParent(item)">
              <template #default>
                <div :class="item.hasLower ? 'haveClo' : 'noClo'">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell class="childs_cell"
                      v-for="(it, idx) in childsTreeList"
                      :key="idx"
                      @click="checkChild(it)">
              <template #title>
                <van-button size="small"
                            :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                            :type="whichTree == 'employee' ? 'warning' : 'primary'"></van-button>
                <span class="check_name">{{ it.keyname }}</span>
              </template>
            </van-cell>
          </div>
        </div>
        <!-- 其他树 -->
        <div v-else>
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <van-cell class="parents_cell other_cell"
                      center
                      v-for="(item, index) in otherTreeList"
                      :key="index + item.keyname">
              <template #icon>
                <van-checkbox v-model="item.checked"
                              @click="tickParent(item)"
                              shape="square"></van-checkbox>
              </template>
              <template #title>
                <van-button size="small"
                            icon="cluster-o"
                            color="#1491f7"></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div v-if="item.hasLower"
                     class="haveClo"
                     @click.stop="hasLower(item)">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
          <!-- 非多选树 -->
          <div v-else>
            <van-cell class="parents_cell other_cell"
                      center
                      v-for="(item, index) in otherTreeList"
                      :key="index + item.keyname"
                      @click="checkChild(item)">
              <template #title>
                <van-button size="small"
                            icon="cluster-o"
                            color="#1491f7"></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div v-if="item.hasLower"
                     class="haveClo"
                     @click.stop="hasLower(item)">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
      <div class="tree_foot"
           v-if="multiseltree">
        <van-button type="primary"
                    size="small"
                    @click="cleanChecked">清除</van-button>
        <van-button type="info"
                    size="small"
                    :disabled="!canComfirm"
                    @click="comfirmChecked">确定</van-button>
      </div>
    </van-popup>

    <!-- 隐私政策弹窗 -->
    <van-dialog class="PrivacyPolicyDia"
                :title="user_agreement_title"
                v-model="showPrivacyPolicy"
                show-cancel-button
                :cancel-button-text="PrivacyTimeOut>0?`同意（${PrivacyTimeOut}S）`:'同意'"
                cancel-button-color="#247dff"
                confirm-button-text="不同意"
                :before-close="beforeClosePrivacy">
      <div class="Privacy_content">
        <iframe id="iframe"
                :src="user_agreement_url"
                style="height:calc(100% - 20px); width: 100%;margin-top: 15px; "
                frameborder="0"></iframe>
        <!-- <div class="Privacy_details"
        v-html="user_agreement_memo"></div>-->
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data'
import { Toast, Dialog, Notify } from 'vant'
import html2canvas from 'html2canvas'
import Compressor from 'compressorjs'
import { parseTime, hex2int, int2hex } from '@/utils'
import { getCommTreeData, upLoadBase64FileFixName } from '@api/wxjx.js'
import { getDownLoadFileName } from '@api/wxzp.js'
import {
  getPreEntryOfferCheckInfo,
  getInfo,
  GetFormFieldDetail,
  GetFormRecordData,
  GetFormFieldExitValue,
  GetFilterDropDownList,
  GetBeforeActionList,
  ProcessBeforeAction,
  PostModuleRecord,
  ProcessAfterAction,
  upLoadFile,
  deleteFile,
  getModuleAttFileList
} from '@api/wx.js'
export default {
  data () {
    const guid = this.$route.query.guid //外部传入的关于当前表单详细信息的唯一guid值
    return {
      areaList, // Vant 官方提供了一份默认的中国省市区数据，可以通过 @vant/area-data 引入：
      guid,
      autoid: 0, // 记录id
      colcount: 1, //表示一行显示几个控件（取值1~8）
      info: '',
      ispost: true, // 是否提交过，如果提交则只读
      moduleno: 304, // 模块号
      show_sign_form: false, // 是否保存签名
      langid: 0,
      username: 'admin',
      userid: 1,
      title: 'Offer信息确认',
      loading: false,
      showCitySelector: false, // 是否显示省市区选择器弹窗
      storageSbject: {}, // 当后台控件类型controltype=maskedit 并且maskeditstr=CitySelector时，选择省份、城市、镇区的联动选择接受的储存中间字段对象
      hasEXfieldlist: [], // 当前数据带出链已经带出过的字段
      logdetail: '', // 有修改的字段
      status: 'modify', // 状态是修改
      seStaList: ['304', '317', '319'],
      formstr: {}, // 保存表单填写数据
      intarry: ['int', 'smallint'], // sql整数类型
      floatarry: ['money', 'float', 'numeric'], // sql小数类型
      datetime: ['datetime', 'smalldatetime'], // sql时间类型
      Selectformdata: {}, // 选中的字段（用于接收弹窗返回信息） 
      sign: null, // 签名文件
      // ----tree
      showTreePop: false, // 树弹窗显示
      whichTree: '', // 树类型
      searchTreeVal: '', // 树弹窗搜索词
      allTreeList: [], // 所有树集合
      parentTreeList: [], // 上级树集合（针对岗位树和员工数）
      childsTreeList: [], // 下级树集合（针对岗位树和员工数）
      clickParentTree: [], // 储存点击过的父节点
      otherTreeList: [], // 其他类型树集合
      multiseltree: false, // 是否是多选树
      canComfirm: false, // 多选树确定按钮是否可以点击
      // -----------------
      //-----------------签名参数sta
      direction: "vertical", // 画板方向 vertical horizontal
      colors: [
        { color: "black", active: true },
        { color: "red", active: false },
        { color: "blue", active: false },
      ], // 可选颜色
      ctx: "", // canvas对象
      stage_info: "", // mycanvas的大小及其相对于视口的位置 
      canvasWidth: "", // canvas宽度
      canvasHeight: "", // canvas高度
      huabuWidth: "",
      huabuHeight: "",
      lineColor: "black", // 线条的颜色
      lineWidth: 2, // 线条宽度
      url: "",
      urlset: '',
      signData: {
        // 提交签名需要的内容
        lawCaseId: "",
        litigantId: "",
        lawyerId: "",
        image: "",
      },
      //-----------------签名end
      //--------------附件参数sta
      use_at_file: false,
      atfilenamelist: [],
      fileList: [],
      fileList2: [],
      selPic: {}, // 选中的格式化附件
      //--------------附件参数end
      fieldlist: [], // 个人资料字段信息
      valuelist: [], // 个人资料字段对应的值
      newFiledList: [],
      activeNames: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20
      ],
      showPicker: false,
      columns: [], // 下拉选项数组
      parentIdx: '', // 数组下标
      chaildIdx: '', // 子数组下标
      showDatePicker: false,
      showDateTimePicker: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2121, 11, 31),
      pickDate: new Date(), // 日期
      pickDateTime: new Date(), // 日期时间
      // edit/combobox/bit/datetime/longdatetime/memo
      isDisable: false,
      saveloading: false, // 保存按钮
      showPrivacyPolicy: false, // 用户协议弹窗
      show_user_agreement: 0, //登录时是否显示用户协议弹窗
      user_agreement_title: '用户隐私策略声明', //协议弹窗标题
      user_agreement_url: 'https://v8.hyclound.cn:8080/useragreement/index.html', // 协议链接页面
      user_agreement_timeout: 30, // 协议弹窗倒计时
      PrivacyTimeOut: '',
      PrivacyTimer: null,
      showContent: true, // 显示容器
    }
  },
  computed: {
    pcuserinfo () {
      let str = ''
      if (this.moduleno == 240)      {
        str = 'system_wx_' + this.moduleno
        // if (this.restype)
        // {
        //   // 区域招聘
        //   str = 'system_' + this.restype
        // }
      } else if (this.seStaList.includes(this.moduleno))      {
        str = 'system_wx_' + this.moduleno // + '_' + this.restype
      }
      return str
    }
  },
  watch: {
    showTreePop (n) {
      if (n == false)      {
        this.parentTreeList = [] // 上级树集合
        this.childsTreeList = [] // 子节点
        this.clickParentTree = [] // 点击过的父节点
        this.otherTreeList = [] // 其他树节点
        this.searchTreeVal = ''
      }
    }
  },
  created () {
    this.getPersonInfo()

    //----------------签名
    // 阻止默认微信系统字体大小的影响
    if (
      typeof WeixinJSBridge == "object" &&
      typeof WeixinJSBridge.invoke == "function"
    )    {
      handleFontSize();
    } else    {
      if (document.addEventListener)      {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent)      {
        document.attachEvent("WeixinJSBridgeReady", handleFontSize);

        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
    }

    function handleFontSize () {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke("setFontSizeCallback", {
        fontSize: 0,
      });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on("menu:setfont", function () {
        WeixinJSBridge.invoke("setFontSizeCallback", {
          fontSize: 0,
        });
      });
    }
  },
  mounted () {
    // if (this.show_sign_form)    {
    //   setTimeout(() => {
    //     this.writeName();
    //   }, 50);
    // }
  },
  methods: {
    // 隐私弹窗同意倒计时
    getTimeCount () {
      const TIME_COUNT = this.user_agreement_timeout
      if (!this.PrivacyTimer)      {
        this.PrivacyTimeOut = TIME_COUNT
        this.PrivacyTimer = setInterval(() => {
          if (this.PrivacyTimeOut > 0 && this.PrivacyTimeOut <= TIME_COUNT)          {
            this.PrivacyTimeOut--
          } else          {
            clearInterval(this.PrivacyTimer)
            this.PrivacyTimer = null
          }
        }, 1000)
      }
    },
    // 隐私弹窗关闭回调
    beforeClosePrivacy (action, done) {
      if (action == 'confirm')      {
        done()
        this.showContent = false
      } else      {
        if (this.PrivacyTimeOut > 0)        {
          done(false)
        } else        {
          // 关闭弹窗
          this.showPrivacyPolicy = false
          this.showContent = true
          this.getFormBuildInfo()
          done()
        }
      }
    },
    // 获取用户协议弹窗参数
    getProtocolPopupParams (moduleno) {
      if (moduleno)      {
        // 协议弹窗参数
        this.show_user_agreement = window['m' + moduleno] ? window['m' + moduleno].show_user_agreement : window.show_user_agreement
        this.user_agreement_title = window['m' + moduleno] ? window['m' + moduleno].user_agreement_title : window.user_agreement_title
        this.user_agreement_url = window['m' + moduleno] ? window['m' + moduleno].user_agreement_url : window.user_agreement_url
        this.user_agreement_timeout = window['m' + moduleno] ? window['m' + moduleno].user_agreement_timeout : window.user_agreement_timeout
      }
      if (this.show_user_agreement == 1)      {
        return true
      } else      {
        return false
      }
    },
    // 打开上传头像
    openUpload (pic) {
      this.selPic = pic
      this.$refs.uploader.chooseFile()
    },
    // 选择图片后，判断是否需要修改命名
    beforeRead (file) {
      // 获取后缀名
      const ext = file.name.substring(file.name.lastIndexOf('.') + 1)
      let url = '',
        name = '',
        type = ''
      if ('doc|docx'.includes(ext))
      {
        name = 'WORD文件'
        type = 'doc'
        url = require('@/assets/img/word.png')
      } else if ('xlsx|xls'.includes(ext))
      {
        name = 'EXCEL文件'
        type = 'xls'
        url = require('@/assets/img/excel2.png')
      } else if ('ppt|pptx'.includes(ext))
      {
        name = 'PPT文件'
        type = 'ppt'
        url = require('@/assets/img/ppt2.png')
      } else if ('pdf'.includes(ext))
      {
        name = 'PDF文件'
        type = 'pdf'
        url = require('@/assets/img/picture.png')
      } else if ('png|jpg|jpeg|emp'.includes(ext))
      {
        name = '图片文件'
        type = 'pic'
        url = require('@/assets/img/picture.png')
      } else if ('zip|rar'.includes(ext))
      {
        name = '压缩文件'
        type = 'zip'
        url = require('@/assets/img/zip.png')
      } else
      {
        name = '其他文件'
        type = 'default'
        url = require('@/assets/img/xmind.png')
      }
      this.selPic.type = null
      this.selPic.url = url
      this.selPic.isImage = false
      this.selPic.ext = ext
      this.selPic.file = this.reFileName(file, this.selPic.name + '.' + ext)
      if (name == '图片文件')
      {
        this.selPic.isImage = true
        this.getBase64(file).then(res => {
          this.selPic.url = res
          this.$forceUpdate()
        })
      }
      return false
    },
    // 删除头像
    del (e) {
      if (e.sfilename)
      {
        this.deletefile(e)
        e.sfilename = null
        e.fileext = null
      }
      e.file = null
      e.url = null
      this.$forceUpdate()
    },
    // 重命名file
    reFileName (file, name) {
      const tempFile = file
      const type = tempFile.type
      const size = tempFile.size
      // const newName = new Date().getTime() + tempFile.name
      const newName =
        name || Math.round(Math.random() * 1000000) + tempFile.name
      let uploadFile = new File([tempFile], newName, { type, size })
      return uploadFile
      //调用文件上传接口上传文件
    },
    // 上传base64附件
    async upLoadBase64 (file, name) {
      let str = ''
      // console.log(file, 'filesize,filesizefilesize');
      // quality: 0.6--67.99% 、0.4--76.18% 、 0.2--83.90%
      let quality = 0.6
      if (file.size >= 1024 * 1024 * 0.5 && 1024 * 1024 * 1 > file.size)      {
        quality = 0.8
      }
      if (file.size >= 1024 * 1024 * 1 && 1024 * 1024 * 2 > file.size)      {
        quality = 0.6
      }
      if (file.size >= 1024 * 1024 * 2 && 1024 * 1024 * 5 > file.size)      {
        quality = 0.4
      }
      if (file.size >= 1024 * 1024 * 5)      {
        quality = 0.2
      }
      let f = name || file.name
      const ext = f.substring(f.lastIndexOf('.') + 1)
      if ('png|jpg|jpeg|emp'.includes(ext))
      {
        // 如果是图片，则先压缩，防止提交失败
        file = await this.compressorImage(file, 'file', quality)
      }
      await this.getBase64(file).then(res => {
        str = res.split('base64,')[1]
      })
      return this.upLoadBase64File(str, name || file.name)
    },
    // 压缩图片
    compressorImage (image, backType, quality) {
      return new Promise((resolve, reject) => {
        new Compressor(image, {
          quality: quality || 0.8,
          success (result) {
            let lastIndexOf = image.name.lastIndexOf('.')
            let name = image.name.substr(0, lastIndexOf) + '.png'
            // console.log(image, 'image.type', name);
            let file = new File([result], name, { type: 'image/png' })
            // console.log(file, 'filefilefile');
            if (!backType || backType == 'blob')
            {
              resolve(result)
            } else if (backType == 'file')
            {
              resolve(file)
            } else
            {
              resolve(file)
            }
          },
          error (err) {
            console.log('图片压缩失败---->>>>>', err)
            reject(err)
          }
        })
      })
    },
    // 上传附件，base64格式
    upLoadBase64File (str, name) {
      const filename = name.substring(0, name.lastIndexOf('.'))
      const fileext = name.substring(name.lastIndexOf('.') + 1)
      // console.log(str)
      return upLoadBase64FileFixName({
        moduleno: this.moduleno,
        upflag: '2',
        autoid: this.autoid,
        uploadpath: 'AttachFile',
        username: this.username,
        filename: filename,
        fileext: fileext,
        filebase64str: str
      })
    },
    // 文件转base64
    getBase64 (file) {
      const tempFile = file
      const type = tempFile.type
      const size = tempFile.size
      // const newName = new Date().getTime() + tempFile.name
      const newName = Math.round(Math.random() * 1000000) + tempFile.name
      let uploadFile = new File([tempFile], newName, { type, size })
      return new Promise(function (resolve, reject) {
        let reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(uploadFile)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    // 上传完成时
    afterReadAttr (file) {
      console.log(file, 'filefile');
      // file.status = 'uploading'
      // file.message = '上传中...'

      // this.upLoadBase64(
      //   file.file,
      //   file.name ? file.name + '.' + file.ext : null
      // ).then(res => {
      //   if (res == 'OK')
      //   {
      //     file.status = 'done'
      //   } else
      //   {
      //     file.status = 'failed'
      //     file.message = '上传失败'
      //     // this.$message({ type: 'error', message: res })
      //     Notify({
      //       type: 'danger',
      //       message: res
      //     })
      //   }

      // })
    },
    // 删除某个附件
    deletefile (file) {
      if (file.type)
      {
        deleteFile({
          dflag: 2,
          moduleno: this.moduleno,
          autoid: this.autoid,
          filename: file.sfilename,
          fileext: file.fileext,
          downloadpath: 'AttachFile'
        }).then(res => { })
      }
    },
    // 文件超出大小方法
    onOversize (file) {
      // console.log(file);
      // this.$message.error('文件大小不能超过 10M')
      Notify({
        type: 'danger',
        message: '文件大小不能超过 10M'
      })
    },
    // 点击签名，初始化画板
    writeName () {
      this.url = "";
      this.$nextTick(() => {
        this.initCanvas();
      });
    },
    // 导入历史签名
    setSign (url) {
      this.urlset = url
    },
    /**
     * 初始化画板，获取canvas节点对象，设置画板的宽高
     * 不能在此方法中设置线条宽度样式，否则无效
     */
    initCanvas () {
      // 获取到当前canvas节点的信息，包含宽，高，top，left等
      let mycanvas = this.$refs.mycanvas; // 获取canvas元素
      // this.huabuWidth = document.body.clientWidth * 0.92;
      // this.huabuHeight = document.body.clientHeight * 0.5;
      let www = document.getElementsByClassName('canvasDiv')[0].clientWidth
      let hhh = document.getElementsByClassName('canvasDiv')[0].clientHeight
      // console.log(www, '-www-', hhh);

      this.canvasWidth = www - 2 // mycanvas.clientWidth - 1; // 获取画板宽度
      this.canvasHeight = hhh - 2 //mycanvas.clientHeight - 1; // 获取画板高度
      // 设置canvas自适应的宽高到标签上（重要,canvas一定要遭标签上标识宽高，否则坐标获取不准确）
      // console.log('clientWidth', this.canvasWidth)
      // console.log('clientHeight', this.canvasHeight)
      mycanvas.width = this.canvasWidth;
      mycanvas.height = this.canvasHeight;

      // canvas基础设置，线条设置
      this.ctx = mycanvas.getContext("2d");
      // 获取mycanvas的大小及其相对于视口的位置
      this.stage_info = mycanvas.getBoundingClientRect();
      // console.log('stage_info', this.stage_info) 
      // this.ctx.beginPath();
      if (this.urlset)
      {
        let u =
          this.urlset.trim() +
          (this.urlset.indexOf('http') > -1
            ? '?v=' + Date.parse(new Date())
            : '')
        this.ctx.beginPath()
        let img = new Image()
        img.src = u
        img.setAttribute("crossOrigin", 'Anonymous')
        img.onload = () => {
          //判断图片是否加载成功
          this.ctx.drawImage(img, 0, 0)
        }
      }
    },
    /**
     * 绘制笔触
     */
    handleDraw (targetX, targetY) {
      // console.log(targetX, targetY)
      this.ctx.lineTo(targetX, targetY); // 将笔触移到当前点击点
      this.ctx.stroke();
    },
    /**
     * 触摸开始
     * 获取当前点击点的坐标
     * 设置线条颜色，宽度，样式等
     */
    onTouchStart (e) {
      // 获取mycanvas的大小及其相对于视口的位置
      let mycanvas = this.$refs.mycanvas;
      this.stage_info = mycanvas.getBoundingClientRect();
      let targetX = e.changedTouches[0].clientX - this.stage_info.left; // 计算起始点X坐标
      let targetY = e.changedTouches[0].clientY - this.stage_info.top; // 计算起始点Y坐标  
      e.preventDefault();
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.lineColor; // 设置线条颜色
      this.ctx.lineWidth = this.lineWidth; // 设置线条的宽度
      this.ctx.lineCap = "round"; // 设置线条的端点的样式，设为圆弧形
      this.ctx.lineJoin = "round"; // 设置线条的连接点的样式，设为弧形
      this.handleDraw(targetX, targetY);
    },
    /**
     * 触摸过程中
     * 获取并计算当前点击点的坐标，绘制线条
     */
    onTouchMove (e) {
      // 获取mycanvas的大小及其相对于视口的位置
      let mycanvas = this.$refs.mycanvas;
      this.stage_info = mycanvas.getBoundingClientRect();
      let targetX = e.changedTouches[0].clientX - this.stage_info.left; // 计算起始点X坐标
      let targetY = e.changedTouches[0].clientY - this.stage_info.top; // 计算起始点Y坐标 
      e.preventDefault();
      this.handleDraw(targetX, targetY);
    },
    /**
     * 触摸结束
     */
    onTouchEnd (e) {
      e.preventDefault();
    },
    /**
     * 颜色模块点击事件，切换点击的颜色状态
     */
    clickColorItem (e) {
      this.colors.forEach((item) => {
        item.active = false;
      });
      e.active = true;
      this.lineColor = e.color; // 设置当前笔触颜色
    },
    /**
     * 重写按钮点击事件，清空画板内容
     */
    clear () {
      // 清空图片
      this.url = "";
      this.urlset = ''
      // 清空画板
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      // 重新设置canvas画板节点对象，否则绘画会出问题,这里异步操作，否则绘画有误
      this.$nextTick(() => {
        this.initCanvas();
      });
    },
    // 旋转base64图片
    rotateBase64Img (src, edg, callback) {
      // 旋转base64图片
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      var imgW; // 图片宽度
      var imgH; // 图片高度
      var size; // canvas初始大小
      if (edg % 90 != 0)      {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; // 旋转象限
      const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; // 裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function () {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant)        {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        ctx.fillStyle = "#fff";
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0)        {
          canvas.width = imgW;
          canvas.height = imgH;
        } else        {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        callback(canvas.toDataURL());
      };
    },
    // ----------------------------------签名方法end
    // base64转file
    dataURLtoFile (base64, filename) {
      var arr = base64.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--)      {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    // 清除日期或时间
    clearDate (index, idx) {
      this.newFiledList[index].origin[idx].fieldValue = null
    },
    // onClickLeft() {
    //   this.$router.push('/personCen')
    // },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm) +
            ':' +
            this.add0(s)
          )
        }
      } else      {
        return ''
      }
    },
    formatter (type, val) {
      if (type === 'year')      {
        return val + '年'
      }
      if (type === 'month')      {
        return val + '月'
      }
      if (type === 'day')      {
        return val + '日'
      }
      return val
    },
    // 日期选择回调
    onDateConfirm (value) {
      this.showDatePicker = false
    },
    // 查询页面信息
    getPersonInfo () {
      getPreEntryOfferCheckInfo({
        guid: this.guid
      }).then(res => {
        if (res.data[0].info)        {
          Toast.fail(res.data[0].info)
        } else        {
          this.autoid = res.data[0].autoid
          this.colcount = res.data[0].colcount
          this.ispost = res.data[0].ispost
          this.moduleno = res.data[0].moduleno
          this.show_sign_form = res.data[0].show_sign_form
          this.langid = res.data[0].langid
          this.userid = res.data[0].userid
          this.username = res.data[0].username
          this.title = res.data[0].title
          this.use_at_file = res.data[0].use_at_file
          // 格式化附件信息处理
          this.atfilenamelist = res.data[0].atfilenamelist ? res.data[0].atfilenamelist.split(',') : []
          this.fileList = []
          if (this.atfilenamelist.length > 0)
          {
            this.atfilenamelist.forEach(el => {
              this.fileList.push({
                url: null,
                name: el,
                file: null,
                type: 'no'
              })
            })
          }

          const showProtocol = this.getProtocolPopupParams(res.data[0].moduleno)
          // 是否弹出协议弹窗
          if (showProtocol)          {
            this.showPrivacyPolicy = true
            this.showContent = false
            this.getTimeCount()
          } else          {
            this.showContent = true
            this.showPrivacyPolicy = false
            this.getFormBuildInfo()
          }

        }
      })
    },
    // 获取页面表单构造
    getFormBuildInfo () {
      this.loading = true
      getInfo({
        moduleno: this.moduleno,
        userid: this.userid,
        langid: this.langid,
        flag: 0,
        mtag: 0
      }).then(res => {
        const data = res.moduleconfiginfo[0]
        // 请求构造表单的接口
        GetFormFieldDetail({
          moduleno: this.moduleno,
          userid: this.userid,
          langid: this.langid,
          checkfieldpri: data.checkfieldpri || false,
          colcount: data.colcount,
          labelwidth: data.labelwidth,
          controlwidth: data.controlwidth,
          mviewname: data.mviewname,
          mkeyname: data.mkeyname,
          formfieldlist: data.formfieldlist,
          autoid: this.autoid
        }).then(res => {
          const fieldlist = res.fieldlist //.filter(it => { return it.formshow })
          const valuelist = res.fieldvalue
          let afterData = []
          let tempArr = []
          if (fieldlist.length > 0 && valuelist.length > 0)          {
            fieldlist.forEach(el => {
              if (this.colcount > 1)              {
                el.width =
                  ((1 / this.colcount) * 100 * el.controlwidth).toFixed(2) +
                  '%'
              }
              el.chnname = el.chnname.replace('<br>', '')
              if (el.isreadonly == 1)              {
                el.isreadonly = true
              } else              {
                el.isreadonly = false
              }
              el.app_canedit = !(
                !el.iswrite ||
                el.isreadonly ||
                !el.isread ||
                this.ispost
              )
              Object.keys(valuelist[0]).forEach((it, index) => {
                if (el.fieldname == it)                {
                  el.fieldValue = valuelist[0][it]
                }
              })
              //  replace(/\\n/g, '\n')  
              if (el.controltype == 'memo')              {
                el.fieldValue = el.fieldValue ? el.fieldValue.replace(/\\n/g, '\n') : ''
              }
            })
            // console.log(fieldlist, 'fieldlistfieldlistfieldlist')

            // 遍历分组
            for (let i = 0; i < fieldlist.length; i++)            {
              if (tempArr.indexOf(fieldlist[i].appgroupname) === -1)              {
                let arr = []
                arr.push(JSON.parse(JSON.stringify(fieldlist[i])))
                afterData.push({
                  name: fieldlist[i].appgroupname,
                  origin: arr
                })
                tempArr.push(fieldlist[i].appgroupname)
              } else              {
                for (let j = 0; j < afterData.length; j++)                {
                  if (afterData[j].name == fieldlist[i].appgroupname)                  {
                    afterData[j].origin.push(
                      JSON.parse(JSON.stringify(fieldlist[i]))
                    )
                    break
                  }
                }
              }
            }
          }
          this.newFiledList = afterData
          // console.log(this.newFiledList, 'newFiledList')
          // 查询附件
          this.getappendix()
          this.loading = false
          // 初始化签名栏
          if (this.show_sign_form)          {
            setTimeout(() => {
              this.writeName();
            }, 500);
          }
          this.updateformstr()
        })
      })
    },
    //获取附件列表
    getappendix () {
      // 获取附件接口信息并处理数据
      getModuleAttFileList({
        moduleno: this.moduleno,
        mautoid: this.autoid,
        username: this.username
      }).then(res => {
        // 读取历史签名
        for (let index = 0; index < res.module_atlist.length; index++)
        {
          const item = res.module_atlist[index]
          if (item.sfilename == 'sign')
          {
            this.setSign(item.url)
            break
          }
        }
        let fileList0 = this.filehandleData(res)
        let emppicArr = fileList0.filter(
          item =>
            item.sfilename === 'emp_' + this.new_acceptid ||
            item.sfilename === 'cap1_' + this.new_acceptid ||
            item.sfilename === 'cap2_' + this.new_acceptid
        )
        let fileList = fileList0.filter(
          item =>
            !(
              item.sfilename === 'emp_' + this.new_acceptid ||
              item.sfilename === 'cap1_' + this.new_acceptid ||
              item.sfilename === 'cap2_' + this.new_acceptid ||
              item.sfilename === 'sign'
            )
        )

        if (this.atfilenamelist.length > 0)
        {
          fileList.forEach(k => {
            let has = false
            k.file = null
            k.name = k.sfilename
            this.fileList.forEach(e => {
              if (e.name == k.name)
              {
                has = true
                e.autoid = k.autoid
                e.crdate = k.crdate
                e.crman = k.crman
                e.file = k.file
                e.fileext = k.fileext
                e.isImage = k.isImage
                e.mautoid = k.mautoid
                e.moduleno = k.moduleno
                e.name = k.name
                e.sfilename = k.sfilename
                e.type = k.type
                e.url = k.url
              }
            })
            // if (!has)
            // {
            //   this.fileList.push(k)
            // }
          })
        } else
        {
          this.fileList = fileList
        }

        for (let index = 0; index < this.fileList.length; index++)
        {
          const item = this.fileList[index]
          // console.log(item, 1111)
          if (item.type == 'pic')
          {
            const params = {
              dflag: '2',
              moduleno: this.moduleno,
              autoid: this.autoid,
              filename: item.sfilename,
              fileext: item.fileext,
              downloadpath: 'AttachFile'
            }
            getDownLoadFileName(params).then(e => {
              let url = e + '?v=' + Date.parse(new Date())
              let img = new Image()
              img.src = url
              img.onload = () => {
                //判断图片是否加载成功
                this.fileList[index].url = url
              }
            })
          }
        }

      })
    }, // 附件数据处理
    filehandleData (list) {
      let groups = [] // 分组[后缀]
      let res = [] // 第一次分类的集合
      if (list.module_atlist)
      {
        list.module_atlist.forEach(item => {
          let type = ''
          let groupName = !item.aftype ? '' : item.aftype
          const ext = item.fileext.slice(1) // 后缀
          let url = ''
          if ('doc|docx'.includes(ext))
          {
            name = 'WORD文件'
            type = 'doc'
            url = require('@/assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext))
          {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@/assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext))
          {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@/assets/img/ppt2.png')
          } else if ('pdf'.includes(ext))
          {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@/assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext))
          {
            name = '图片文件'
            type = 'pic'
            url = require('@/assets/img/picture.png')
          } else if ('zip|rar'.includes(ext))
          {
            name = '压缩文件'
            type = 'zip'
            url = require('@/assets/img/zip.png')
          } else
          {
            name = '其他文件'
            type = 'default'
            url = require('@/assets/img/xmind.png')
          }
          item.type = type
          item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      if (list.other_atlist)
      {
        list.other_atlist.forEach(item => {
          let type = ''
          const ext = item.fileext.slice(1) // 后缀
          let groupName = !item.aftype ? '' : item.aftype
          let url = ''
          if ('doc|docx'.includes(ext))
          {
            name = 'WORD文件'
            type = 'doc'
            url = require('@/assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext))
          {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@/assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext))
          {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@/assets/img/ppt2.png')
          } else if ('pdf'.includes(ext))
          {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@/assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext))
          {
            name = '图片文件'
            type = 'pic'
            url = require('@/assets/img/picture.png')
          } else if ('zip|rar'.includes(ext))
          {
            name = '压缩文件'
            type = 'zip'
            url = require('@/assets/img/zip.png')
          } else
          {
            name = '其他文件'
            type = 'default'
            url = require('@/assets/img/xmind.png')
          }
          item.type = type
          item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      return res
    },
    // 获取下拉选内容
    getPickerData (it, index, idx) {
      this.parentIdx = index // 数组下标
      this.chaildIdx = idx
      const listsql = it.listsql
      this.columns = []
      GetFilterDropDownList({
        moduleno: this.moduleno,
        username: this.username,
        listsql: listsql
      }).then(res => {
        if (res.data.length > 0)        {
          for (let i = 0; i < res.data.length; i++)          {
            this.columns.push(res.data[i].dicvalue)
          }
        }
        // console.log(res)
      })
      this.showPicker = true
    },
    // 下拉弹窗确认回调
    onConfirm (value) {
      this.newFiledList[this.parentIdx].origin[
        this.chaildIdx
      ].fieldValue = value
      this.showPicker = false
    },
    // 点击日期选择事件
    getDatePicker (index, idx) {
      this.parentIdx = index // 数组下标
      this.chaildIdx = idx
      this.showDatePicker = true
    },
    // 日期选择回调
    onDateConfirm (value) {
      const val = this.formatDate(value, 'yyyy-MM-dd')
      this.pickDate = val
      this.newFiledList[this.parentIdx].origin[this.chaildIdx].fieldValue = val
      this.showDatePicker = false
    },
    // 点击日期时间选择事件
    getDateTimePicker (index, idx) {
      this.parentIdx = index // 数组下标
      this.chaildIdx = idx
      this.showDateTimePicker = true
    },
    // 日期时间选择回调
    onDateTimeConfirm (value) {
      const val = this.formatDate(value)
      this.pickDateTime = val
      this.newFiledList[this.parentIdx].origin[this.chaildIdx].fieldValue = val
      this.showDateTimePicker = false
    },
    // 格式化makedit类型输入内容（目前数字类型）
    keyup (list) {
      // console.log(list, 'list');
      if (list.fieldValue)      {
        if (list.maskeditstr == 'Int')        {
          // 正负整数和0
          list.fieldValue = list.fieldValue.replace(/[^\-?\d]/g, '')
          if (list.fieldValue.lastIndexOf('-') > 0)          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('-')
            )
          }
          let ary = list.fieldValue.split('')
          if (ary.length > 0)          {
            if (ary.length > 1 && ary[0] == 0)            {
              list.fieldValue = ary[1]
            } else if (ary.length > 1 && ary[0] == '-')            {
              if (ary[1] == 0 || ary[1] == '-')              {
                list.fieldValue = ''
              }
            }
          }
        } else if (list.maskeditstr == 'PositiveInt')        {
          // 正整数
          list.fieldValue = list.fieldValue.replace(/^(0+)|[^\d]+/g, '')
        } else if (list.maskeditstr == 'NegtiveInt')        {
          // 负整数
          list.fieldValue = list.fieldValue.replace(/^(0+)|[^\-?\d]+/g, '')
          if (list.fieldValue.lastIndexOf('-') > 0)          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('-')
            )
          }
          let ary = list.fieldValue.split('')
          if (ary.length > 0 && ary[0] == '-')          {
            if (ary[0] == '-' && ary[1] == 0)            {
              list.fieldValue = ary[0]
            }
          } else          {
            list.fieldValue = ''
          }
        } else if (
          list.maskeditstr.indexOf('Float') != -1 ||
          list.maskeditstr.indexOf('PositiveFloat') != -1 ||
          list.maskeditstr.indexOf('NegtiveFloat') != -1
        )        {
          list.fieldValue = list.fieldValue.replace(/[^\-?\d.]/g, '')
          let ary = list.fieldValue.split('')
          if (list.fieldValue.lastIndexOf('-') > 0)          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('-')
            )
          }
          if (ary[0] == '.')          {
            list.fieldValue = ''
          }
          if (ary.length > 1 && ary[0] == 0 && ary[1] != '.')          {
            list.fieldValue = ary[1]
          }
          if (list.fieldValue.split('.').length > 2)          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('.')
            )
          }
          if (ary[0] == '-')          {
            if (ary[1] == '.')            {
              list.fieldValue = '-'
            } else if (ary[1] == 0 && ary[2] == 0)            {
              list.fieldValue = '-0'
            } else if (ary[1] == 0 && ary[2] && ary[2] != 0 && ary[2] != '.')            {
              list.fieldValue = '-' + ary[2]
            }
          }
        }
      }
    },
    // 当后台控件类型controltype=maskedit 并且maskeditstr=CitySelector 时，需要在移动端或pc端显示选择省份、城市、镇区的联动选择器
    citySelectorFocus (it) {
      this.showCitySelector = true
      this.storageSbject = it
      // it.fieldvalue = 'nishizenmele?'
    },
    // 选择省份、城市、镇区的联动选择器确定按钮
    confirmCity (result) {
      let str = ''
      if (result.length > 0)      {
        result.forEach(el => {
          str += el.name ? el.name : ''
        })
      }
      this.storageSbject.fieldValue = str
      this.showCitySelector = false
    },
    // 内容改变事件
    b4valuechage (val, list) {
      this.valuechage(list.fieldValue, list)
    },
    // 表单change事件（判断是否触发数据带出）
    valuechage (val, list) {
      // this.$emit('setCanSave', true)
      this.hasEXfieldlist = []
      list.haschange = true
      list.newvalue = val
      //先触发退出提醒
      // if (
      //   list.isexitshowinfo &&
      //   list.fieldValue !== '' &&
      //   list.fieldValue !== null
      // )
      // {
      //   //启用退出提醒
      //   let fun = this.ExitInfo
      //   this.bulidExitValue(list.fieldname, val, fun)
      // }
      if (list.isexitvalue)      {
        //启用数据带出
        let fun = this.ExitValue
        this.bulidExitValue(list.fieldname, val, fun)
      }
    },
    // 构造数据带出结构体，发起数据打出请求
    bulidExitValue (n, v, fun) {
      // console.log(n, v, fun, 111111111)

      this.updateformstr()
      const totalformstr = JSON.stringify(this.formstr)
      const data = {
        editstate: 2,
        moduleno: this.moduleno,
        fieldname: n,
        fieldvalue: v,
        username: this.username,
        formstr: totalformstr
      }
      if (typeof fun === 'function')      {
        fun(data)
      }
    },
    // 数据带出
    ExitValue (data) {
      if (this.hasEXfieldlist.indexOf(data.fieldname) == -1)      {
        this.hasEXfieldlist.push(data.fieldname)
        GetFormFieldExitValue(data).then(res => {
          const data = res.data
          data.forEach(item => {
            if ('isreadonly' in item)            {
              // 如果带出过程携带isreadonly，则修改对应字段的isreadonly值
              if (item.isreadonly != null)
                this.setReadOnly(item.fieldname, item.isreadonly, 'isreadonly')
            }

            if ('mustinput' in item)            {
              // 如果带出过程携带mustinput，则修改对应字段的mustinput值
              if (item.mustinput != null)
                this.setReadOnly(item.fieldname, item.mustinput, 'mustinput')
            }
            if (item.fieldvalue != '<OrgFieldValue>')
              this.setValue(item.fieldname, item.fieldvalue)
            // // 将返回的数据赋值给对应表格
            // this.form.loopData.forEach(it => {
            //   it.forEach(i => {
            //     if (i.fieldname === item.fieldname) {
            //       i.fieldvalue = item.fieldvalue
            //     }
            //   })
            // })
          })
        })
      }
    },
    // 数据带出修改只读状态
    setReadOnly (n, isreadonly, key) {
      this.newFiledList.forEach(item => {
        let origin = item.origin
        origin.forEach(i => {
          if (i.fieldname == n)          {
            i[key] = isreadonly
          }
        })
      })
      this.$forceUpdate()
    },
    // 设置字段值（不触发数据带出）
    setValue (n, v, tar = 'new') {
      this.newFiledList.forEach(item => {
        let origin = item.origin
        origin.forEach(i => {
          if (i.fieldname == n)          {
            let vv = v
            /*数据转化处理
              （bit类型要把 1 转成 true 其他为false）
              日期控件需要转成date格式
              readonly 的情况要转成字符串
            */
            if (i.fielddatatype === 'bit')            {
              const truearr = [1, '1', 'true', 'True', 'TRUE', true]
              if (this.status !== 'readonly')              {
                vv = truearr.indexOf(v) != -1 ? true : false
              } else              {
                vv = truearr.indexOf(v) != -1 ? '是' : '否'
              }
            } else if (
              i.controltype === 'datetime' &&
              vv !== null &&
              this.status !== 'readonly'
            )            {
              // vv = vv.replace(/-/g, '/')
              // vv = new Date(vv)
              // if (!(vv instanceof Date && !isNaN(vv.getTime()))) {
              //   // console.log(i.fieldname, vv, v)
              //   return false
              // }
            } else if (i.controltype === 'colorcombobox' && vv !== null)            {
              vv = '#' + int2hex(vv)
            } else if (i.controltype === 'memo' && vv !== null)            {
              vv = vv.replace(/\\n/g, '\n ')
            }
            i.fieldValue = vv
            if (tar === 'old')            {
              i.oldvalue = vv
              i.haschange = false
              i.newvalue = ''
            } else if (tar === 'new')            {
              i.haschange = true
              i.newvalue = vv
            }
          }
        })
      })

      this.$forceUpdate()
    },
    // 更新formstr
    updateformstr () {
      this.logdetail = ''
      let logdetailarr = []

      this.newFiledList.forEach(item => {
        let origin = item.origin
        origin.forEach(it => {
          this.formstr[it.fieldname] =
            it.fieldValue === null || it.fieldValue === undefined
              ? 'null'
              : it.fieldValue === '' &&
                (it.controltype === 'datetime' ||
                  this.datetime.indexOf(it.fielddatatype) != -1 ||
                  this.floatarry.indexOf(it.fielddatatype) != -1 ||
                  this.intarry.indexOf(it.fielddatatype) != -1)
                ? 'null'
                : it.fieldValue === ''
                  ? it.fieldValue
                  : it.controltype === 'datetime'
                    ? it.fieldValue
                    : it.controltype === 'longdatetime'
                      ? parseTime(it.fieldValue)
                      : it.fieldValue && it.controltype === 'colorcombobox'
                        ? hex2int(it.fieldValue)
                        : it.fieldValue.toString()

          if (it.haschange && this.status === 'modify')          {
            logdetailarr.push(
              '{' +
              it.fieldname +
              ',' +
              it.chnname +
              ',' +
              it.oldvalue +
              ',' +
              it.newvalue +
              '}'
            )
          }
        })
      })
      this.logdetail = logdetailarr.join(',')
      // if (this.sqnum)
      // {
      //   this.formstr.sqnum = this.sqnum
      // }
      if (this.moduleno == 240)      {
        this.formstr.crman = this.pcuserinfo
      }

      // 主表附件数 添加到formstr
      if (this.atfilenamelist.length > 0)      {
        // 主表每个字典附件
        this.fileList.forEach((e, i) => {
          this.formstr['#masterfilecount_' + e.name] = e.url != null ? 1 : 0
        })
      } else      {
        this.formstr['#masterfilecount_total'] = this.fileList.length
      }
      // console.log(this.logdetail,this.status,'this.logdetail');
      // this.$emit('updateformstr', this.formstr, this.logdetail)
      // console.log(this.formstr, 'this.formstr');
      return this.formstr
    },
    // 弹出下拉树
    showTree (e) {
      this.multiseltree = e.multiseltree
      this.Selectformdata = e
      this.showTreePop = true
      if (
        e.poptreename.indexOf('stationtree') > -1 ||
        e.poptreename == 'stationidtree'
      )      {
        this.whichTree = 'station'
      } else if (e.poptreename == 'emptree')      {
        this.whichTree = 'employee'
      } else      {
        this.whichTree = 'other'
      }
      // console.log(this.Selectformdata, 'this.Selectformdata ')
      this.getCommTree(e.multiseltree)
      // this.$refs.addtree.show = true //开发中
    },
    // 获取通用树
    getCommTree (multiseltree) {
      const editstate = 2
      getCommTreeData({
        moduleno: parseInt(this.moduleno),
        treename: this.Selectformdata.poptreename, // 树名
        username: this.username,
        fieldname: this.Selectformdata.fieldname,
        editstate: editstate,
        formstr: JSON.stringify(this.formstr)
      }).then(res => {
        // 如果是多选树，则返回记录添加是否多选，是否选中属性
        if (multiseltree)        {
          res.data.forEach(element => {
            element.multiple = multiseltree
            element.checked = false
          })
        }
        this.allTreeList = res.data
        // 树结构中最小层级(最上层)
        let mini = Math.min.apply(
          Math,
          res.data.map(item => {
            return item.levelid
          })
        )
        if (this.whichTree == 'station' || this.whichTree == 'employee')        {
          // 岗位和员工树
          // 初始时的父节点
          this.parentTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断父节点有没有下级
          if (this.parentTreeList.length > 0)          {
            this.parentTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++)              {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid)                {
                  el.hasLower = true
                  break
                }
              }
            })
          }
          // 初始时的子节点
          this.allTreeList.forEach(el => {
            if (this.parentTreeList.length == 1)            {
              // 最大父节点只有一个的时候
              let nodecan = false
              if (this.whichTree == 'station')              {
                nodecan = el.issta
              } else if (this.whichTree == 'employee')              {
                nodecan = el.isdept == 0 && el.levelid == 16
              }
              if (nodecan && el.parentid == this.parentTreeList[0].keyid)              {
                this.childsTreeList.push(el)
              }
            } else            {
              // 最大父节点有多个的时候
              this.childsTreeList = []
            }
          })
        } else        {
          // 其他树(暂时部门树)
          // 初始时的节点
          this.otherTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断初始节点有没有下级
          if (this.otherTreeList.length > 0)          {
            this.otherTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++)              {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid)                {
                  el.hasLower = true
                  break
                }
              }
            })
          }
        }
        console.log(this.allTreeList, 'this.allTreeList')
      })
    },
    // 搜索子节点
    onSearchTree (val) {
      // 清空父节点、子节点和已点击过的父节点
      this.parentTreeList = []
      this.childsTreeList = []
      this.clickParentTree = []
      this.otherTreeList = []
      this.allTreeList.forEach(el => {
        let nodecan = false
        if (this.whichTree == 'station')        {
          nodecan = el.issta
        } else if (this.whichTree == 'employee')        {
          nodecan = el.isdept == 0 && el.levelid == 16
        }
        // 岗位和人员
        if (this.whichTree == 'station' || this.whichTree == 'employee')        {
          if (nodecan && el.keyname.indexOf(val) !== -1)          {
            this.childsTreeList.push(el)
          }
        } // 其他（部门）
        else        {
          if (el.keyname.indexOf(val) !== -1)          {
            // 判断节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == el.keyid
            })
            if (lowerAry.length > 0)            {
              el.hasLower = true
            } else            {
              el.hasLower = false
            }
            this.otherTreeList.push(el)
          }
        }
      })
    },
    // 回到某个树节点
    backTo (val) {
      if (val === true)      {
        // 点击的开始,回到最初始状态
        this.parentTreeList = []
        this.childsTreeList = []
        this.clickParentTree = []
        this.otherTreeList = []
        this.searchTreeVal = ''
        this.allTreeList = []
        this.getCommTree(this.multiseltree)
      } else      {
        // 否则先在clickParentTree去掉该父节点及节点后所有节点
        let ary = [] // 记录该节点及后的所有节点
        for (let i = 0; i < this.clickParentTree.length; i++)        {
          const ele = this.clickParentTree[i]
          if (val.keyid == ele.keyid)          {
            // 如果是数组最后一个则不可以点击，数据不变
            if (i == this.clickParentTree.length - 1)            {
              return false
            } else            {
              ary = this.clickParentTree.filter((item, index) => {
                return index < i
              })
            }
          }
        }
        this.clickParentTree = ary
        // 去掉之后再区分（岗位和员工）和其他树
        if (this.whichTree == 'station' || this.whichTree == 'employee')        {
          // 岗位和员工树
          // 再获取该父节点下的子节点
          this.checkParent(val)
        } else        {
          // 其他树
          this.hasLower(val)
        }
      }
    },
    // 其他树点击下级
    hasLower (val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower)      {
        this.clickParentTree.push(val)
      }
      // 获取此节点下所有子节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      if (array.length > 0)      {
        this.otherTreeList = []
        array.forEach(item => {
          // 判断节点有没有下级
          let lowerAry = this.allTreeList.filter(it => {
            return it.parentid == item.keyid
          })
          if (lowerAry.length > 0)          {
            item.hasLower = true
          } else          {
            item.hasLower = false
          }
          // 父节点
          this.otherTreeList.push(item)
        })
      }
    },
    // 点击树的父节点(针对岗位和员工树)
    checkParent (val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower)      {
        this.clickParentTree.push(val)
      }
      // 获取此父节点下所有节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      // 遍历所有节点并分出父节点和子节点
      if (array.length > 0)      {
        this.parentTreeList = []
        this.childsTreeList = []
        array.forEach(item => {
          let nodecan = false
          if (this.whichTree == 'station')          {
            nodecan = item.issta
          } else if (this.whichTree == 'employee')          {
            nodecan = item.isdept == 0 && item.levelid == 16
          }
          if (nodecan)          {
            // 子节点
            this.childsTreeList.push(item)
          } else          {
            // 判断父节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == item.keyid
            })
            if (lowerAry.length > 0)            {
              item.hasLower = true
            } else            {
              item.hasLower = false
            }
            // 父节点
            this.parentTreeList.push(item)
          }
        })
      }
    },
    // 选择父节点复选框
    tickParent (item) {
      // 判断父节点有没有下级
      let lowerAry = this.allTreeList.filter(it => {
        return it.parentid == item.keyid
      })
      if (lowerAry.length > 0)      {
        if (item.checked == true)        {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid)              {
                it.checked = true
              }
            })
            this.tickParent(ele)
          })
        } else        {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid)              {
                it.checked = false
              }
            })
            this.tickParent(ele)
          })
        }
      }
    },
    // 多选树清除复选框选中
    cleanChecked () {
      this.allTreeList.forEach(item => {
        item.checked = false
      })
    },
    // 多选树确认复选框选中
    comfirmChecked () {
      let array = this.allTreeList.filter(ele => {
        return ele.checked == true
      })
      let trueList = []
      if (array.length > 0)      {
        if (this.whichTree == 'station')        {
          for (let i = 0; i < array.length; i++)          {
            const it = array[i]
            if (it.issta == true)            {
              trueList.push(it)
            }
          }
        } else if (this.whichTree == 'employee')        {
          for (let l = 0; l < array.length; l++)          {
            const item = array[l]
            if (item.isdept == 0 && item.levelid == 16)            {
              trueList.push(item)
            }
          }
        } else        {
          // 其他树
          trueList = array
        }
      }
      this.checkChild(trueList)
      console.log(trueList, 'trueListtrueList')
    },
    // 点击树的子节点(暂时单选树)(针对岗位和员工树)
    checkChild (it) {
      console.log(this.Selectformdata, 'Selectformdata', it);

      let checked = []
      this.hasEXfieldlist = []
      if (this.Selectformdata.multiseltree)      {
        checked = it
        //多选树
        const namelist = checked.map(it =>
          it.keyname.indexOf(' ') != -1 ? it.keyname.split(' ')[0] : it.keyname
        )
        const names = namelist.join(',')
        this.setValue(this.Selectformdata.fieldname, String(names))
        // 下拉字段带出过程
        if (this.Selectformdata.isexitvalue)        {
          this.valuechage(names, this.Selectformdata) //判断数据带出
        }
      } else      {
        checked = [it]
        //单选树
        if (this.whichTree == 'station' || this.whichTree == 'employee')        {
          this.setValue(
            this.Selectformdata.fieldname,
            String(
              checked[0].keyname.indexOf(' ') != -1
                ? checked[0].keyname.split(' ')[0]
                : checked[0].keyname
            )
          )
        } else        {
          this.setValue(
            this.Selectformdata.fieldname,
            String(
              checked[0].keyname.indexOf(' ') != -1
                ? checked[0].keyname.split(' ')[1]
                : checked[0].keyname
            )
          )
        }
        this.setValue(
          this.Selectformdata.popupdatefield,
          String(checked[0][this.Selectformdata.popbackkeyid])
        )
        // 下拉字段带出过程
        if (this.Selectformdata.isexitvalue)        {
          this.valuechage(
            String(
              checked[0].keyname.indexOf(' ') != -1
                ? checked[0].keyname.split(' ')[0]
                : checked[0].keyname
            ),
            this.Selectformdata
          ) //判断数据带出
        }
        //转化字段带出过程
        this.newFiledList.forEach(item => {
          let origin = item.origin
          origin.forEach(it => {
            if (
              it.isexitvalue &&
              it.fieldname === this.Selectformdata.popupdatefield
            )            {
              this.valuechage(checked[0][this.Selectformdata.popbackkeyid], it) //判断数据带出
            }
          })
        })
      }
      // 最终关闭弹窗
      this.showTreePop = false
    },
    // 保存按钮
    async savePersonInfo () {
      if (this.checkmustinput(true))      {
      } else      {
        this.updateformstr()
        this.saveloading = true
        // 如果需要签名就弹窗 
        if (this.show_sign_form)        {
          this.url = this.$refs.mycanvas.toDataURL();
          if (this.url != "")          {
            // 追加base64签名图片,转换角度(横屏不需要旋转图片角度)
            this.rotateBase64Img(this.url, this.direction == 360, (base64data) => {
              this.signData.image = base64data;
              this.sign = this.dataURLtoFile(base64data, 'sign.png')
              this.beforeAction(null, false, this.sign)
            });
          } else          {
            Notify({
              type: 'danger',
              message: '请签名！'
            })
            return
          }
          // this.beforeAction(null, true, null).then(res => {
          //   let cmip = !res
          //   if (!cmip)            {
          //     this.showsign = true
          //   }
          // })
        } else        {
          // this.saveAction()
          this.beforeAction(null, false, null)
        }
      }
    },
    // 判断必填字段是否已经填写
    checkmustinput (b4multi) {
      let msg = false
      for (let o = 0; o < this.newFiledList.length; o++)      {
        let element = this.newFiledList[o]
        for (let k = 0; k < element.origin.length; k++)        {
          let i = element.origin[k]
          if (i.mustinput && (i.fieldValue === null || i.fieldValue === ''))          {
            // this.$message({ type: 'error', message: i.chnname + ' 必填！' })
            Notify({
              type: 'danger',
              message: i.chnname + ' 必填！'
            })
            this.$emit('changPage', o, 'main')
            msg = true
            return msg
          }
        }
      }
      return msg
    },
    // 保存前动作
    // onlycheck=true  只执行保存前过程，检查是否存在报错，没有报错则显示签名框。如果flase表示是执行保存
    beforeAction (json, onlycheck = false, file) {
      let promptdata1 = []
      return new Promise(async (resolve, reject) => {
        this.updateformstr()
        if (json)        {
          for (var jn in json)          {
            this.formstr[jn] = String(json[jn]) //这个要传字符串，无论是数字类型还是Boolean类型，都要转换成字符串类型，这是接口要求
          }
        }
        try        {
          const res1 = await GetBeforeActionList({
            // 先请求保存接口1，获得acprocname
            actype: 0,
            moduleno: this.moduleno,
            username: this.username
          })
          const arr = res1.data // acprocname数组

          for (const item of arr)          {
            const editstate =
              this.new_acceptid === '' ||
                this.new_acceptid === null ||
                this.new_acceptid === '0' ||
                this.new_acceptid === 0
                ? 1
                : 2

            const result = await ProcessBeforeAction({
              acproname: item.acprocname,
              editstate: 2,
              moduleno: this.moduleno,
              username: this.username,
              autoid: this.autoid,
              formstr: JSON.stringify(this.formstr) // 表格填写信息
            })

            if (result.data.length > 0)            {
              promptdata1.push(result.data[0])
            } else            {
              promptdata1.push({
                isinfo: true,
                info: result.errormsg,
                showbox: true,
                cansave: false
              })
            }
          }
        } catch (e)        {
          promptdata1.push({
            isinfo: true,
            info: e.errormsg,
            showbox: true,
            cansave: false
          })
        }

        this.saveAction(promptdata1, onlycheck, file)
          .then(res => {
            resolve(true)
          })
          .catch(err => {
            resolve(false)
          })
      })
    },
    // 保存动作+接口
    saveAction (n, onlycheck, file) {
      // // 获取保存前策略过程列表
      this.formstr.autoid = this.autoid
      return new Promise(async (resolve, reject) => {
        const arr = n
        const saveornot = []
        // 处理formstr数字转换成字符串
        for (const key in this.formstr)        {
          if (!isNaN(this.formstr[key]))          {
            this.formstr[key] = String(this.formstr[key])
          }
        }
        for (let i = 0; i < arr.length; i++)        {
          if (arr[i].isinfo && !arr[i].cansave)          {
            Notify({
              type: 'danger',
              message: arr[i].info
            })
            saveornot.push(1)
            this.$emit('setCanSave', true)
            reject()
            return
          } else if (arr[i].isinfo && arr[i].cansave)          {
            const a = await this.ask(arr[i].info)
            if (!a)            {
              saveornot.push(0)
              // 当点击取消时取消保存或提交
              this.$emit('setCanSave', true)
              return false
            }
            resolve()
          }
        }
        if (saveornot.length > 0)        {
          if (saveornot.some(i => i == 1))          {
            Notify({
              type: 'danger',
              message: '保存失败'
            })
          }
          reject()
          this.saveloading = false
        } else        {
          // 这里接入主表的签名确认
          if (onlycheck)          {
            // this.$emit('showQM')
            resolve()
          } else          {
            // 签名完毕
            let userName = 'system_wx'

            if (this.moduleno == 240)            {
              this.formstr.crman = this.pcuserinfo
              userName = this.pcuserinfo
            } else if (this.moduleno == 317)            {
              // this.formstr.crman = 'system_lt'
              userName = 'system_lt'
            } else if (this.moduleno == 319)            {
              // this.formstr.crman = 'system_xy'
              userName = 'system_xy'
            } else if (this.moduleno == 304)            {
              // this.formstr.crman = 'system_wx'
              userName = 'system_wx'
              // this.formstr.tj_empid = this.tj_empid
            } else if (this.moduleno == 322)            {
              // this.formstr.crman = 'system_wx'
              userName = 'system_pq'
            } else            {
              // this.formstr.crman = 'system_wx'
              userName = 'system_wx'
            }

            PostModuleRecord({
              moduleno: this.moduleno,
              editstate: 2,
              // pcuserinfo: this.pcuserinfo, // 用户名称,
              pcuserinfo: userName,
              formstr: JSON.stringify(this.formstr),
              logdetail: this.logdetail
            }).then(async res => {
              if (res.data[0].info)              {
                Notify({
                  type: 'danger',
                  message: res.data[0].info
                })
              } else              {
                // 如果要保存签名就去截取整个页面（包括签名）并上传附件
                if (this.show_sign_form)                {
                  await this.getPageToPic().then(base64Url => {
                    // console.log(base64Url, 'dataURLdataURL');
                    if (base64Url)                    {
                      const pageFile = this.dataURLtoFile(base64Url, 'offersign.png')
                      upLoadFile({
                        moduleno: this.moduleno,
                        upflag: '2',
                        autoid: this.autoid,
                        uploadpath: 'AttachFile',
                        username: this.username,
                        file: pageFile
                      })
                    }
                  })
                }
                //触发保存后动作
                ProcessAfterAction({
                  actype: 0,
                  editstate: 2,
                  moduleno: this.moduleno,
                  username: this.username, // 用户名称,
                  autoid: this.autoid,
                  formstr: JSON.stringify(this.formstr)
                }).finally(() => {
                  // 如果要保存签名
                  if (this.show_sign_form && file)                  {
                    upLoadFile({
                      moduleno: this.moduleno,
                      upflag: '2',
                      autoid: this.autoid,
                      uploadpath: 'AttachFile',
                      username: this.username,
                      file: file
                    })
                  }
                  // 附件
                  let fileAry = []
                  this.fileList.forEach(f => {
                    fileAry.push(f)
                  })
                  let filenum = 0
                  fileAry.forEach(f => {
                    if (!f.type)
                    {
                      filenum++
                    }
                  })
                  if (filenum > 0)
                  {
                    // 上传附件
                    let num = 0
                    fileAry.forEach((f, index) => {
                      if (!f.type)
                      {
                        f.status = 'uploading'
                        f.message = '上传中...'
                        this.upLoadBase64(
                          f.file,
                          f.name ? f.name + '.' + f.ext : null
                        ).then(res => {
                          num++
                          if (res == 'OK')
                          {
                            f.status = 'done'
                          } else
                          {
                            f.status = 'failed'
                            f.message = '上传失败'
                            // this.$message({ type: 'error', message: res })
                            Notify({
                              type: 'danger',
                              message: res
                            })
                          }
                          if (num == filenum)
                          {
                            // 保存成功
                            // 保存后重新获取一次当前记录的所有字段
                            Toast.success(this.$t('module.SaveSuc'));
                            this.getFormBuildInfo()
                          }
                        })
                      }
                    })
                  } else
                  {
                    // 保存成功
                    // 保存后重新获取一次当前记录的所有字段
                    Toast.success(this.$t('module.SaveSuc'));
                    this.getFormBuildInfo()
                  }
                })
              }
              this.saveloading = false
            })
          }
        }
      })
    },
    ask (msg) {
      // element弹窗
      return new Promise((resolve, reject) => {
        // this.$confirm(msg, '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // })
        Dialog.confirm({
          title: '系统提示',
          message: msg
        })
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    // 如果有签名就要截取整个界面包括签名
    getPageToPic () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const ref = this.$refs.offerCheckCollapse.$el // 截图区域 
          // const ref = document.getElementsByClassName('van-collapse')[0] 
          // const dom2 = document.getElementsByClassName('OfferCheck_content')[0]
          // let copyDom = dom1.cloneNode(true) // 克隆截图区域dom
          // copyDom.style.width = dom1.scrollWidth + "px"
          // copyDom.style.height = dom1.scrollHeight + "px"
          // copyDom.style.position = 'fixed'
          // // $(copyDom).css('top', '110px')
          // // copyDom.style.top = -120 + "px"
          // copyDom.style.left = -2000 + "px"
          // dom2.append(copyDom) // 复制dom插入节点
          html2canvas(ref, {
            dpi: 300,
            allowTaint: false, scrollY: 0,
            backgroundColor: '#e8f4ff',
            useCORS: true // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
          }).then(canvas => {
            // dom2.removeChild(copyDom) // 截图完之后删除复制dom节点
            const dataURL = canvas.toDataURL('image/png')
            resolve(dataURL)
            // console.log(dataURL)
            // window.open().document.write('<html><head><title>渲染图</title></head><body><div style=\'text-align:center\'><img src="' + dataURL + '" /></div></body></html>')
          })
        }, 100)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.preEntryOfferCheck {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .OfferCheck_content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    /deep/.van-collapse {
      .van-collapse-item {
        .van-collapse-item__title {
          .van-cell__title {
            font-size: 0.48rem;
            font-family: Source Han Sans CN;
            color: #000;
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .peField {
              width: 100%;
              .van-field {
                .van-field__label {
                  width: 8em;
                }
              }
              .not_canedi {
                .van-field__value {
                  .van-field__body {
                    .van-field__control {
                      color: #999999;
                    }
                  }
                }
              }
            }
            // 签名
            .sign_form {
              .canvasContainer {
                width: 96%;
                // height: 65%;
                background: #fff;
                border-radius: 16px;
              }
              .canvasContainer .signTitle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 28px;
                color: #666;
                height: 60px;
                padding-left: 3%;
                padding-right: 4%;
              }
              .canvasContainer .signFooter {
                height: 70px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: nowrap;
                // border-top: 1px solid #ccc;
                margin-top: 20px;
                .button_can {
                  cursor: pointer;
                  width: 20%;
                  text-align: center;
                  // color: #999;
                  font-size: 28px;
                  height: 100%;
                  line-height: 90px;
                }
              }
              .canvasDiv {
                width: 92% !important;
                height: 600px;
                position: relative;
                margin: 0 auto;
                display: block !important;
              }
              .canvasDiv.windowsignremark {
                height: 650px;
              }
              #canvas {
                background: #fff;
                cursor: crosshair;
                border: 1px dashed rgb(204, 204, 204);
                /* 判断方向旋转辅助线 */
                /* border-bottom: 1px solid red; */
                height: 100%;
                width: 100%;
              }

              .mydraw {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            // 格式化附件
            .picbox {
              width: 100%;
              padding: 10px 0;
              display: flex;
              align-items: center;
              // justify-content: space-around;
              flex-wrap: wrap;
              .pp {
                width: 33%;
                font-size: 30px;
                text-align: center;
                position: relative;
                .van-image {
                  cursor: pointer;
                }
                .van-uploader__preview-delete {
                  cursor: pointer;
                  position: absolute;
                  right: 0;
                  top: 0;
                  z-index: 1;
                  .van-icon {
                    font-size: 14px;
                    color: #fff;
                    position: absolute;
                    right: 5px;
                    top: 7px;
                    z-index: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
  .tree_popup {
    z-index: 6666 !important;
    .tree_title {
      height: 90px;
      text-align: center;
      span {
        font-size: 36px;
        font-weight: bold;
      }
    }
    .tree_search {
      height: 100px;
    }
    .tree_head {
      height: 90px;
      white-space: nowrap;
      overflow-x: auto;
      display: flex;
      align-items: center;
      span {
        font-size: 32px;
      }
      .theLast {
        color: #969799;
        margin-right: 10px;
      }
      .noLast {
        color: #1491f7;
      }
      .head_start {
        color: #1491f7;
        padding: 0 10px 0 20px;
      }
      .division {
        color: #969799;
        padding: 0 16px;
      }
    }
    .van-divider::before {
      border-width: 20px 0 0;
    }

    .tree_body {
      height: calc(100% - 5.7rem);
      overflow: auto;
      .parents_cell {
        .van-checkbox {
          padding-right: 10px;
        }
        .van-cell__title {
          flex: 1 1 83%;
        }
        .van-cell__value {
          flex: 1 1 17%;
          div {
            display: flex;
            align-items: center;
            justify-content: end;
            border-left: 1px solid #969799;
          }
          .haveClo {
            color: #1491f7;
          }
          .noClo {
            color: #969799;
          }
          .van-icon {
            padding: 0 10px;
          }
        }
      }
      .childs_cell,
      .other_cell {
        .van-cell__title {
          display: flex;
          align-items: center;
          .van-button {
            border-radius: 12px;
            font-size: 28px;
          }
          .check_name {
            padding-left: 10px;
          }
        }
      }
      // .other_cell {
      //   .van-cell__title {
      //     display: flex;
      //     align-items: center;
      //     .van-button {
      //       border-radius: 12px;
      //       font-size: 28px;
      //     }
      //   }
      // }
    }
    .tree_foot {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: space-evenly;
      .van-button {
        width: 30vw;
        margin-bottom: 10px;
      }
    }

    /deep/.van-popup__close-icon--top-left {
      top: 25px !important;
      left: 10px !important;
    }
  }

  .PrivacyPolicyDia {
    width: 80%;
    /deep/.van-dialog__header {
      padding-top: 0;
      height: 110px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-weight: bold;
      font-size: 40px;
    }
    /deep/.van-dialog__content {
      padding: 0 40px;
      .Privacy_content {
        // max-height: 65vh;
        height: 70vh;
        // overflow-y: auto;
        color: #646566;
      }
    }
  }
}
</style>
